.footer-bolot-color {
    background-color: #1f1f1f;
}

.color-white {
    color: #fff !important;
}

:root,
:host {
    --fa-style-family-brands: 'Font Awesome 6 Brands';
    --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands';
}

@font-face {
    font-family: 'Font Awesome 6 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("./assets/plugins/fontawesome/webfonts/fa-brands-400.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-brands-400.ttf") format("truetype");
}

.fa,
.fa-brands,
.fa-classic,
.fa-regular,
.fa-sharp,
.fa-solid,
.fab,
.far,
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto
}

.fa-brands,
.fab {
    font-family: "Font Awesome 6 Brands"
}

.fa-brands,
.fab {
    font-weight: 400
}

.fa-linkedin:before {
    content: "\f08c"
}

.fa-twitter:before {
    content: "\f099"
}

.fa-youtube:before {
    content: "\f167"
}

.fa-facebook:before {
    content: "\f09a"
}

.fa-square-instagram:before {
    content: "\e055"
}

.fa-facebook-f:before {
    content: "\f39e";
}