@font-face {
    font-family: 'Font Awesome 6 Free';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("./assets/plugins/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-regular-400.ttf") format("truetype");
}

.far,
.fa-regular {
    font-weight: 400;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-display: block;
    font-weight: 400;
    src: url("./assets/plugins/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'FontAwesome';
    font-display: block;
    src: url("./assets/plugins/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-regular-400.ttf") format("truetype");
    unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}

.fa-solid,
.fa-regular,
.fab,
.fa-brands {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}

.fa-solid,
.fa-regular {
    font-family: 'Font Awesome 6 Free';
}

.fa-clock::before {
    content: "\f017";
}

@font-face {
    font-family: 'Font Awesome 6 Free';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("./assets/plugins/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-solid-900.ttf") format("truetype");
}

.fas,
.fa-solid {
    font-weight: 900;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-display: block;
    font-weight: 900;
    src: url("./assets/plugins/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-solid-900.ttf") format("truetype");
}

@font-face {
    font-family: 'FontAwesome';
    font-display: block;
    src: url("./assets/plugins/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("./assets/plugins/fontawesome/webfonts/fa-solid-900.ttf") format("truetype");
}

.fa-book-open::before {
    content: "\f518";
}

.small-text {
    font-size: 0.8em; /* Adjust the size as desired */
    vertical-align: top; /* Adjust alignment if needed */
    color: #555; /* Optional: lighter color for the taxes text */
  }